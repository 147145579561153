import { API_URL, API_URL_BACKEND_ARTICLES } from "@constants/backend.env";
import { IArticle } from "@interfaces/backoffice/article";
import { articleResponse } from "@scripts/ArticlesUtils";
import { useAxios } from "use-axios-client";

const useGetArticleById = (id: string) =>
  useAxios<IArticle>({
    baseURL: API_URL,
    url: API_URL_BACKEND_ARTICLES + id,
    transformResponse: [
      (data) => {
        try {
          let article = JSON.parse(data);
          return articleResponse(article);
        } catch (error) {
          console.log(error);
        }
      },
    ],
  });

export default useGetArticleById;
