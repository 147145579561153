export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const WHALE_CONTRACT_ADDRESS =
  process.env.REACT_APP_WHALE_CONTRACT_ADDRESS;
export const API_YOUTUBE_URL = process.env.REACT_APP_YOUTUBE_URL;
export const API_YOUTUBE_PART = process.env.REACT_APP_YOUTUBE_PART;
export const API_YOUTUBE_PLAYLISTID = process.env.REACT_APP_YOUTUBE_PLAYLISTID;
export const API_YOUTUBE_CHANNELID = process.env.REACT_APP_YOUTUBE_CHANNELID;
export const API_YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_KEY;
export const WALLET_ADDRESS_MOCK = process.env.REACT_APP_WALLET_ADDRESS_MOCK;
export const WALLET_ADDRESS_TO_MOCK: string[] | undefined =
  process.env.REACT_APP_WALLET_ADDRESS_TO_MOCK?.split(",");

// NOT USED ANYMORE. ABLE TO BE REMOVED

// export const API_RSS_URL = process.env.REACT_APP_RSS_URL;
// export const API_RSS_KEY = process.env.REACT_APP_RSS_KEY;
// export const API_MEDIUM_HIGHLIGHT =
//   process.env.REACT_APP_MEDIUM_CATEGORY_HIGHLIGHT;
// export const API_TWITCH_CHANNEL = process.env.REACT_APP_TWITCH_CHANNEL;
