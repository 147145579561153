import { ReactElement } from "react";
import styles from "./ArticleEmbed.module.scss";
import { RichTextView } from "@nftstudios/rich-text-editor-component";

interface IArticleEmbed {
  body: string;
}

const ArticleEmbed = ({ body }: IArticleEmbed): ReactElement => (
  <div className={styles.container}>
    <RichTextView className={styles.body}>{body}</RichTextView>
  </div>
);

export default ArticleEmbed;
