import Title from "@components/Title";
import Button from "@components/Button";
import styles from "./VaultValuation.module.scss";
import { useWeb3 } from "react-dapp-web3";
import useGetReports from "@hooks/backoffice/useGetVaultReport";
import useGetRoles from "@hooks/backoffice/useGetRoles";
import { Variant } from "@components/Button/Button";

const VaultValuation = () => {
  const { isWalletConnected, connect } = useWeb3();
  const vaultReport = useGetReports();
  const vaultReportData = vaultReport.data && vaultReport.data[0];
  const { data, loading } = useGetRoles();

  return (
    <>
      <Title text={"VAULT VALUATION"} tag="h3" line />
      {vaultReportData && (
        <div className={styles.anualReport}>
          <img src={vaultReportData.imageUrl} alt="anual report banner" />
          <Button onClick={connect} variant={Variant.Primary}>
            {!isWalletConnected ? (
              "CONNECT WALLET"
            ) : !data?.isMinimumValue && !loading ? (
              `WHALE Members only. You need ${data?.role.value} $WHALE to access this report`
            ) : (
              <a
                href={vaultReportData.reportUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                CHECK REPORT
              </a>
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default VaultValuation;
