export const HOME = "/";
export const WHALE_TALES = "/whale-tales";
export const WHALE_FM = "/whale-fm";
export const WHALE_VAULT = "/whale-vault";
export const WHALE_MEMBERS = "/whale-members";
export const WHALE_PARTNERS = "/whale-partners";
export const WHALE_WHITEPAPER = "/whale-whitepaper";
export const FAQ = "/faq";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const ARTICLE_DETAIL = `${WHALE_TALES}/:id/article-detail`;
export const MEMBERS_ARTICLE_DETAIL = `${WHALE_MEMBERS}/:id/article-detail`;
export const PREVIEW_ARTICLE_DETAIL = `${WHALE_TALES}/:id/article-detail/:preview`;
