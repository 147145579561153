export const API_URL = process.env.REACT_APP_API_BACKEND_URL;
export const API_URL_BACKEND_HIGHLIGHTS = "/whaleshark/highlights/";
export const API_URL_BACKEND_REPORT = "/whaleshark/vault/report/";
export const API_URL_BACKEND_CAROUSEL = "/whaleshark/vault/carousel/";
export const API_URL_BACKEND_PARTNER = "/whaleshark/partners/";
export const API_URL_BACKEND_BENEFITS = "/whaleshark/benefits";
export const API_URL_BACKEND_GLANCES = "/whaleshark/glances";
export const API_URL_BACKEND_CALENDAR = "/whaleshark/calendars/";
export const API_URL_BACKEND_FGINDEX = "/whaleshark/fearGreedIndex/last";
export const API_URL_BACKEND_ROLES = "/whaleshark/roles/";
export const API_URL_BACKEND_ARTICLES = "/whaleshark/articles/";
