import { ReactElement } from "react";
import Parser from "html-react-parser";
import Title from "@components/Title";
import useGetHighlight from "@hooks/backoffice/useGetVaultHighlights";
import styles from "./HighlightsVault.module.scss";

const HighlightsVault = (): ReactElement => {
  const { data } = useGetHighlight();
  const artVault = data && data[0];

  return (
    <div className={styles.container}>
      {artVault && (
        <>
          <Title text="Highlights of the vault" tag="h3" line fixedWidth />
          <div className={styles.vault}>
            <div className={styles.artInformation}>
              <h5>{artVault.title}</h5>
              <p className={styles.sign}>{artVault.collectionName}</p>
              <p className={styles.note}>{Parser(artVault.description)}</p>
              <hr />
              <p className={styles.link}>
                <a
                  href={artVault.openSeaUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discover
                </a>
              </p>
            </div>
            <div className={styles.imageContainer}>
              <img src={artVault.imageUrl} alt="highlights vault" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default HighlightsVault;
