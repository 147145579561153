import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { useWeb3 } from "react-dapp-web3";

export interface IGetByWalletConfig extends AxiosRequestConfig {
  urlGenerator?: (walletAddress: string) => string;
}

export interface IData<T> {
  data: T | null;
  loading: boolean;
}

const useAxiosByWallet = <T>(config: IGetByWalletConfig): IData<T> => {
  const { walletAddress } = useWeb3();
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      if (walletAddress) {
        setLoading(true);
        await axios({
          ...config,
          url: config.urlGenerator
            ? config.urlGenerator(walletAddress)
            : config.url,
        })
          .then((response: { data: T }) => {
            setData(response.data);
          })
          .catch((error: Error) => console.log(error.message))
          .finally(() => setLoading(false));
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return { data, loading };
};

export default useAxiosByWallet;
