import { ReactElement } from "react";
import { Link } from "react-router-dom";
import {
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  WHALE_FM,
  WHALE_VAULT,
  WHALE_TALES,
} from "@constants/routes";
import {
  DISCORD,
  TWITTER,
  YOUTUBE,
  TWITCH,
  MEDIUM,
  CENT,
  TELEGRAM,
  REDDIT,
} from "@constants/socialMedia.links";
import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nftstudios_logo from "@assets/images/utils/nftstudios-logo.png";
import whale_logo from "@assets/images/utils/whale-brand-logo.png";
import { NFTSTUDIOS_URI } from "@constants/config";

const Footer = (): ReactElement => (
  <footer className={styles.container}>
    <div className={styles.maxWithContainer}>
      <div className={styles.leftSection}>
        <img src={whale_logo} alt="WhaleLogo" />
        <p>
          The Omni-versal membership club of the digital art & culture
          renaissance
        </p>

        <div className={styles.socialMedia}>
          <a
            href={DISCORD}
            target="_blank"
            rel="noopener noreferrer"
            title="Discord"
          >
            <FontAwesomeIcon
              icon={["fab", "discord"]}
              color="white"
              size="1x"
            />
          </a>
          <a
            href={TWITTER}
            target="_blank"
            rel="noopener noreferrer"
            title="Twitter"
          >
            <FontAwesomeIcon
              icon={["fab", "twitter"]}
              color="white"
              size="1x"
            />
          </a>
          <a
            href={YOUTUBE}
            target="_blank"
            rel="noopener noreferrer"
            title="YouTube"
          >
            <FontAwesomeIcon
              icon={["fab", "youtube"]}
              color="white"
              size="1x"
            />
          </a>
          <a
            href={TWITCH}
            target="_blank"
            rel="noopener noreferrer"
            title="Twitch"
          >
            <FontAwesomeIcon icon={["fab", "twitch"]} color="white" size="1x" />
          </a>
          <a
            href={MEDIUM}
            target="_blank"
            rel="noopener noreferrer"
            title="Medium"
          >
            <FontAwesomeIcon icon={["fab", "medium"]} color="white" size="1x" />
          </a>
          <a href={CENT} target="_blank" rel="noopener noreferrer" title="Cent">
            <FontAwesomeIcon
              icon={["fas", "comment-dots"]}
              color="white"
              size="1x"
            />
          </a>
          <a
            href={TELEGRAM}
            target="_blank"
            rel="noopener noreferrer"
            title="Telegram"
          >
            <FontAwesomeIcon
              icon={["fab", "telegram"]}
              color="white"
              size="1x"
            />
          </a>
          <a
            href={REDDIT}
            target="_blank"
            rel="noopener noreferrer"
            title="Reddit"
          >
            <FontAwesomeIcon
              icon={["fab", "reddit-alien"]}
              color="white"
              size="1x"
            />
          </a>
        </div>

        <p className={styles.sign}>
          powered by
          <a
            href={NFTSTUDIOS_URI}
            target="_blank"
            rel="noopener noreferrer"
            title="NFTStudios"
          >
            <img src={nftstudios_logo} alt="NFTStudios" />
          </a>
        </p>
      </div>

      <div className={styles.links}>
        <div className={styles.navBar}>
          <p>Our work</p>
          <Link to={WHALE_TALES}>Whale Tales</Link>
          <Link to={WHALE_FM}>Whale FM</Link>
          <Link to={WHALE_VAULT}>Whale Vault</Link>
          {/* <Link to={WHALE_WHITEPAPER}>Whale Whitepaper</Link> */}
        </div>
        <div className={styles.navBar}>
          <p>Help</p>
          <Link to={TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
          <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
          {/* <Link to={FAQ}>FAQ</Link> */}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
