import { API_URL, API_URL_BACKEND_ROLES } from "@constants/backend.env";
import { IWhaleRoleProfile } from "@interfaces/backoffice/whaleRoleProfile";
import { walletToMock } from "@scripts/walletReplaceMock";
import useAxiosByWallet from "./useAxiosByWallet";

const useGetRoles = () =>
  useAxiosByWallet<IWhaleRoleProfile>({
    baseURL: API_URL,
    urlGenerator: (walletAddress: string) =>
      API_URL_BACKEND_ROLES + walletToMock(walletAddress),
  });

export default useGetRoles;
