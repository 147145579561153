import { useWeb3 } from "react-dapp-web3";
import useGetRoles from "@hooks/backoffice/useGetRoles";

const useCheckIsMember = () => {
  const { isWalletConnected } = useWeb3();
  const { data, loading } = useGetRoles();

  const isMember = isWalletConnected && !loading && data?.isMinimumValue;

  return { isMember };
};

export default useCheckIsMember;
